import styled from "styled-components";
import {useEffect, useState} from "react";
import SportsSoccerRoundedIcon from '@mui/icons-material/SportsSoccerRounded';

const CardContainer = styled.div`
    background: linear-gradient(to bottom, #262d45, #36516e);
    color: #ffffff;
    border-radius: 5px;
    width: ${props => props.width || '100%'};
    margin: 20px 0 5px 0;

    p {
        text-align: center;
    }
`;

const CardContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    //align-items: center;
    text-align: center;
    height: 100%;
`;

const VS = styled.div`
    background-color: #7c859a;
    border-radius: 30px;
    padding: 0 7px 2px 6px;
    height: 20px;
    margin-top: 30px;
`;

const TeamData = styled.div`
    display: flex;
    padding: 10px 5px;
    border-radius: 5px;
    flex-direction: column;
    //justify-content: space-between;
    align-items: center;
    width: 40%;
    min-height: 100%;

`;

const TeamName = styled.p`
    margin: 0 0 5px 0;
    overflow: hidden;
    font-size: 1.1rem;
    text-overflow: ellipsis;
    max-width: 100%
`;

const GamePhase = styled.p`
    margin: 0 0 15px 0;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 550;
`;

const TeamGoals = styled.p`
    margin: 5px 0 0 0;
    width: fit-content;
    padding: 3px 6px 4px 6px;
    border-radius: 5px;
    background-color: ${props => props.state === 0 ? "#daa51f" : props.state === 2 ? "#daa51f" : "#daa51f"};

`;



export default function CustomClassificationGameCard({data, width, scorers}) {
    const [winner, setWinner] = useState(null);

    useEffect(() => {
        if (data['equipa-1'].golos > data['equipa-2'].golos)
            setWinner(0);
        else if (data['equipa-1'].golos === data['equipa-2'].golos)
            setWinner(1);
        else
            setWinner(2);
    });

    // Função para formatar a hora do jogo
    const getGameTime = (dateTime) => {
        const time = new Date(dateTime);
        return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    return (
        <CardContainer width={width}>
            <div style={{height: "50%", padding: "13px 20px 13px 20px"}}>
                {scorers && (
                    data.fase === 'Grupo' ? (
                        <GamePhase>{data.grupo} <br/>{getGameTime(data.hora)}</GamePhase>
                    ) : data.fase === 'Meia Final' ? (
                        <GamePhase>Meia Final <br/>{getGameTime(data.hora)}</GamePhase>
                    ) : data.fase === '5Classificado' ? (
                        <GamePhase>5.º e 6.º Classifcado<br/>{getGameTime(data.hora)}</GamePhase>
                    ): data.fase === '3Classificado' ? (
                        <GamePhase>3.º e 4.º Classifcado<br/>{getGameTime(data.hora)}</GamePhase>
                    ):(
                        <GamePhase>Final <br/>{getGameTime(data.hora)}</GamePhase>
                    ))}
                <CardContentContainer>
                    <TeamData>
                        <TeamName>
                            {data['equipa-1'].nome}
                        </TeamName>
                        <TeamGoals state={winner}>
                            {data['equipa-1'].golos}
                        </TeamGoals>
                        {scorers && (
                            data['equipa-1'].marcadores.map((scorer, index) => (
                                <div style={{display: "flex", alignItems: "center", margin: "10px 3px 0px 3px"}}>
                                    <SportsSoccerRoundedIcon/>
                                    <p style={{fontSize: "15px", margin: "0 3px"}}
                                       key={index}>{scorer.player_name}</p>
                                </div>
                            )))}
                    </TeamData>
                    <VS>
                        vs
                    </VS>
                    <TeamData>
                        <TeamName>
                            {data['equipa-2'].nome}
                        </TeamName>
                        <TeamGoals state={!winner}>
                            {data['equipa-2'].golos}
                        </TeamGoals>
                        {scorers && (
                            data['equipa-2'].marcadores.map((scorer, index) => (
                                <div style={{display: "flex", alignItems: "center", margin: "10px 3px 0px 3px"}}>
                                    <SportsSoccerRoundedIcon/>
                                    <p style={{fontSize: "15px", margin: "0 3px"}}
                                       key={index}>{scorer.player_name}</p>
                                </div>
                            )))}
                    </TeamData>
                </CardContentContainer>
            </div>
        </CardContainer>
    );
}

