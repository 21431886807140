import React, {useEffect, useState} from 'react';
import CustomContainer from "../components/reusableComponents/customContainer";
import CustomTable from "../components/reusableComponents/customTable";
import CustomCard from "../components/reusableComponents/customCard";
import {
    fetchTop10ScorersData,
    fetchTop10CardsData,
    fetchTopGuardData,
    fetchTopScoringTeamsData
} from "../fetch/fetchTournamentData";
import styled from "styled-components";
import Classification from "../components/Classification";
import Footer from "../layout/Footer";


const DoubleCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
`;

const MainContentContainer = styled.div`
    flex: 1 1 67%;
    margin-bottom: 25px;
`;


export default function Stats() {
    const [scorersData, setScorersData] = useState(null);
    const [guardsData, setGuardsData] = useState(null);
    const [cardsData, setCardsData] = useState(null);
    const [topScoringTeamsData, setTopScoringTeamsData] = useState(null);

    const [loading, setLoading] = useState(true);
    const [classificationLoading, setClassificationLoading] = useState(true);

    useEffect(() => {

        fetchTop10ScorersData().then((jsonData) => {
            setScorersData(jsonData);
        }).catch((error) => {
            console.error('Error fetching scorers data:', error)
        });

        fetchTopGuardData().then((jsonData) => {
            setGuardsData(jsonData);
        }).catch((error) => {
            console.error('Error fetching guard data:', error)
        });

        fetchTop10CardsData().then((jsonData) => {
            setCardsData(jsonData);
        }).catch((error) => {
            console.error('Error fetching cards data:', error)
        });

        fetchTopScoringTeamsData().then((jsonData) => {
            setTopScoringTeamsData(jsonData);
        }).catch((error) => {
            console.error('Error fetching data:', error);
        });
    }, []);

    useEffect(() => {
        if (scorersData && guardsData && cardsData && topScoringTeamsData) {
            setLoading(false);
        }
    }, [scorersData, guardsData, cardsData, topScoringTeamsData]);

    return (
        <>
            <CustomContainer title="Estatísticas">
                {!loading && (
                    <GridContainer>
                        {!classificationLoading && (
                            <MainContentContainer>
                                <CustomCard>
                                    <h3>Melhores marcadores</h3>

                                        <CustomTable
                                            data={scorersData}
                                            headers={[
                                                {label: '', key: 'posicao', align: 'center'},
                                                {label: 'Nome', key: 'nome'},
                                                {label: 'Equipa', key: 'nome_equipa'},
                                                {label: 'Golos', key: 'golos', align: 'center'},
                                            ]}
                                            width="100%"
                                        />

                                </CustomCard>
                                <DoubleCardContainer>
                                    <CustomCard style={{marginTop: "25px", width: "48%"}}>
                                        <h3>Melhores defesas</h3>

                                            <CustomTable
                                                data={guardsData}
                                                headers={[
                                                    {label: '', key: 'posicao', align: 'center'},
                                                    {label: 'Equipa', key: 'nome'},
                                                    {label: 'Golos sofridos', key: 'golos', align: 'center'},
                                                ]}
                                            />

                                    </CustomCard>
                                    <CustomCard style={{marginTop: "25px", width: "48%"}}>
                                        <h3>Melhores ataques</h3>

                                            <CustomTable
                                                data={topScoringTeamsData}
                                                headers={[
                                                    {label: '', key: 'posicao', align: 'center'},
                                                    {label: 'Equipa', key: 'equipa'},
                                                    {label: 'Golos marcados', key: 'golos', align: 'center'},
                                                ]}
                                            />

                                    </CustomCard>
                                </DoubleCardContainer>
                                <CustomCard style={{marginTop: "25px"}}>
                                    <h3>Mais cartões</h3>
                                        <CustomTable
                                            data={cardsData}
                                            headers={[
                                                {label: '', key: 'posicao', align: 'center'},
                                                {label: 'Nome', key: 'name'},
                                                {label: 'Equipa', key: 'team_name'},
                                                {label: 'Amarelos', key: 'yellow_card', align: 'center'},
                                                {label: 'Vermelhos', key: 'red_card', align: 'center'},
                                            ]}
                                        />

                                </CustomCard>
                            </MainContentContainer>
                        )}
                        <Classification showEliminationGames={true} showActiveGames={true}
                                        style={{flex: "1 1 30%", marginBottom: "20px"}}
                                        onLoadingChange={setClassificationLoading}/>
                    </GridContainer>
                )}
            </CustomContainer>
            {!loading && !classificationLoading && <Footer/>}
        </>
    );
}
