import styled from "styled-components";

const CardContainer = styled.div`
    background-color: #ffffff;
    height: min-content;
    max-width: 84vw;

    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    h3 {
        margin-top: 0;
        margin-bottom: 15px;
        font-weight: 500;
        color: #002639;
    }

    h4 {
        margin-top: 25px;
        text-align: center;
    }

    @media (max-width: 768px) {
        width: 100% !important;
    }
`;

const CardDataContainer = styled.div`
    padding: 23px 23px;
`;
export default function CustomCard({children, style}) {
    return (
        <CardContainer style={style}>
            <CardDataContainer>
                {children}
            </CardDataContainer>
        </CardContainer>
    );
}