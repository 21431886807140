import React, {useEffect, useState} from "react";
import CustomContainer from "../components/reusableComponents/customContainer";
import {fetchTournamentName, fetchGamesData} from "../fetch/fetchTournamentData";
import CustomClassificationGameCard from "../components/reusableComponents/customClassificationGameCard";
import CustomCard from "../components/reusableComponents/customCard";
import Classification from "../components/Classification";
import Footer from "../layout/Footer";
import styled from "styled-components";

const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
`;

const MainContentContainer = styled.div`
    flex: 1 1 67%;
    margin-bottom: 25px;
`;

export default function Games() {
    const [tournament, setTournament] = useState(null);
    const [tournamentLoading, setTournamentLoading] = useState(true);
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [classificationLoading, setClassificationLoading] = useState(true);

    useEffect(() => {

        fetchTournamentName().then((jsonData) => {
            setTournamentLoading(false);
        }).catch((error) => {
                console.error('Error fetching data:', error);
            }
        );

        fetchGamesData().then((jsonData) => {
            setGames(jsonData);
        }).catch((error) => {
            console.error('Error fetching data:', error);
        });

    }, []);

    useEffect(() => {
        if (games) {
            setLoading(false);
        }
    }, [games]);

    // TODO: Implementar filtros

    return (
        <>
            {!tournamentLoading && (
                <>
                    <CustomContainer title={tournament}>
                        {!loading && (
                            <GridContainer>
                                {!classificationLoading && (
                                    <MainContentContainer>
                                        <CustomCard>
                                            <h3 style={{margin: "0"}}>Jogos</h3>
                                            {games.length > 0 ? (
                                                    <>
                                                        {games.length > 0 && (
                                                            <div>
                                                                {games.map((game, index) => (
                                                                    <CustomClassificationGameCard key={index} data={game}
                                                                                                  scorers={true}
                                                                                                  width="100%"/>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </>
                                                ) :
                                                <h4>Não existem jogos</h4>}
                                        </CustomCard>
                                    </MainContentContainer>
                                )}
                                <Classification style={{flex: "1 1 30%", marginBottom: "20px"}}
                                                onLoadingChange={setClassificationLoading}/>
                            </GridContainer>
                        )}
                    </CustomContainer>
                    {!loading && !classificationLoading && <Footer/>}
                </>
            )}
        </>
    );
}