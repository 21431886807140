import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import Divider from '@mui/material/Divider';
import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";
import img from '../assets/campo.jpg';

const HomeCarouselContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    @media (max-width: 768px) {
        display: none;
    }
`;

const GridContainer = styled.div`
    padding: 20px 15px;
    box-sizing: border-box;
    text-align: center;
    min-height: 100%;
    color: #9ba7bd;
    transition: color 0.3s ease-in-out;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        color: #ffffff !important;

        & > svg {
            fill: currentColor;
            transition: fill 0.3s ease;
        }

    }
`;

const OverlayGrid = styled.div`
    border-radius: 3px 3px 5px 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: absolute;
    background-color: #171e36;
    bottom: -20%;
    width: 83%;
    box-sizing: border-box;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const BottomLine = styled.div`
    width: 100%;
    background-color: #7d879a;
    padding: 0 0 8px 0;
    border-radius: 0 0 3px 3px;
`;

const PhoneHomeCarouselContainer = styled.div`
    //display: flex;
    justify-content: center;
    margin: 7% 8%;
    border-radius: 3px 3px 5px 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: #171e36;
    //margin-bottom: 100px;

    @media (min-width: 768px) {
        display: none;
    }
`;

const calendarEventTitle = "XXII Edição do Troféu do Reitor";
const calendarEventStartDate = "20240425T100000";
const calendarEventEndDate = "20240425T180000";
const calendarEventLocation = "Quinta de São Roque (Quinta da Universidade)";

const href = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(calendarEventTitle)}&dates=${calendarEventStartDate}/${calendarEventEndDate}&location=${encodeURIComponent(calendarEventLocation)}&sf=true&output=xml`;

export default function HomeCarousel() {
    return (
        <>
            <HomeCarouselContainer>
                <Carousel showArrows={false} autoPlay={false} width={"100%"} showStatus={false} showThumbs={false}
                          showIndicators={false} interval={4000} dynamicHeight={false} infiniteLoop={true}>
                    <div style={{width: "100%", height: "400px"}}>
                        <img src={img} alt="carousel 1" style={{width: "100%", height: "100%", objectFit: "cover"}}/>
                    </div>
                    <div style={{width: "100%", height: "400px"}}>
                        <img src={img} alt="carousel 2" style={{width: "100%", height: "100%", objectFit: "cover"}}/>
                    </div>
                </Carousel>
                <OverlayGrid>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <GridContainer style={{width: "30%"}}>
                            <div>
                                <CalendarMonthRoundedIcon fontSize="large"/>
                                <h3 style={{margin: "10px 0 0 0", fontSize: "1.1rem", fontWeight: "500"}}>14 de
                                    setembro de 2024</h3>
                            </div>
                            <a style={{color: "#DAA520", textDecoration: "none", fontSize: "1rem", fontWeight: "500"}}
                               href={href}
                               target="_blank" rel="noreferrer">Adiciona ao teu calendário</a>
                        </GridContainer>
                        <Divider sx={{backgroundColor: "#7c859a"}} orientation="vertical" variant="middle" flexItem/>
                        <GridContainer style={{width: "40%"}}>
                            <h2 style={{margin: "15px 0 10px 0", fontSize: "1.8rem", fontWeight: "700"}}>XXIV Edição do
                                Troféu do
                                Reitor</h2>
                            <a style={{color: "#DAA520", textDecoration: "none", fontSize: "1rem", fontWeight: "500"}}
                               target="_blank"
                               href={"https://trofeudoreitor.pt/"} rel="noreferrer">Incrições e Regulamento</a>
                        </GridContainer>
                        <Divider sx={{backgroundColor: "#7c859a"}} orientation="vertical" variant="middle" flexItem/>
                        <GridContainer style={{width: "30%"}}>
                            <div>
                                <LocationOnRoundedIcon fontSize="large"/>
                                <h3 style={{margin: "10px 0 10px 0", fontSize: "1.1rem", fontWeight: "500"}}>Campus
                                    Universitário de
                                    São Roque</h3>
                            </div>
                            <a style={{color: "#DAA520", textDecoration: "none", fontSize: "1rem", fontWeight: "500"}}
                               target="_blank" href={"https://goo.gl/maps/1hGUcED5c3sCoULh8"}
                               rel="noreferrer">Localização</a>
                        </GridContainer>
                    </div>
                    <BottomLine/>
                </OverlayGrid>
            </HomeCarouselContainer>
            <PhoneHomeCarouselContainer>
                <GridContainer style={{width: "100%"}}>
                    <h2 style={{margin: "0 0 10px 0", fontSize: "1.8rem", fontWeight: "700"}}>XXII Edição do
                        Troféu do
                        Reitor</h2>
                    <a style={{color: "#DAA520", textDecoration: "none", fontSize: "1rem", fontWeight: "500"}}
                       target="_blank"
                       href={"https://trofeudoreitor.pt/"} rel="noreferrer">Incrições e Regulamento</a>
                </GridContainer>
                <Divider sx={{backgroundColor: "#7c859a"}} variant="middle" flexItem/>
                <GridContainer style={{width: "100%"}}>
                    <div style={{marginBottom: "10px"}}>
                        <CalendarMonthRoundedIcon fontSize="large"/>
                        <h3 style={{margin: "10px 0 0 0", fontSize: "1.1rem", fontWeight: "500"}}>25 de
                            abril de 2024</h3>
                    </div>
                    <a style={{color: "#DAA520", textDecoration: "none", fontSize: "1rem", fontWeight: "500"}}
                       href={href}
                       target="_blank" rel="noreferrer">Adiciona ao teu calendário</a>
                </GridContainer>
                <Divider sx={{backgroundColor: "#7c859a"}} variant="middle" flexItem/>
                <GridContainer style={{width: "100%"}}>
                    <div>
                        <LocationOnRoundedIcon fontSize="large"/>
                        <h3 style={{margin: "10px 0 10px 0", fontSize: "1.1rem", fontWeight: "500"}}>Campus
                            Universitário de
                            São Roque</h3>
                    </div>
                    <a style={{color: "#DAA520", textDecoration: "none", fontSize: "1rem", fontWeight: "500"}}
                       target="_blank" href={"https://goo.gl/maps/1hGUcED5c3sCoULh8"}
                       rel="noreferrer">Localização</a>
                </GridContainer>
            </PhoneHomeCarouselContainer>
        </>
    );
}
