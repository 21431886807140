import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
    margin: 3% 5%;
    font-size: large;
`;

const Title = styled.h1`
    font-weight: 600;
    font-size: 2.7rem;
    font-family: "Poppins", sans-serif;
    color: #002639;
`;

const Container = styled.div`
    margin: 3% 4%;
`;

export default function CustomContainer({children, title}) {
    return (
        <MainContainer>
            {title ? <Title>{title}</Title> : ""}
            <Container>
                {children}
            </Container>
        </MainContainer>
    );
}