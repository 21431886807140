import React, {useEffect, useState} from "react";
import CustomContainer from "../components/reusableComponents/customContainer";
import styled from "styled-components";
import CustomCard from "../components/reusableComponents/customCard";
import CustomTable from "../components/reusableComponents/customTable";
import Classification from "../components/Classification";
import {
    fetchPlayersData,
    fetchTeamsData
} from "../fetch/fetchTournamentData";
import Footer from "../layout/Footer";

const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
`;

const MainContentContainer = styled.div`
    flex: 1 1 67%;
    margin-bottom: 25px;
`;

export default function PlayersAndTeams() {
    const [loading, setLoading] = useState(true);
    const [classificationLoading, setClassificationLoading] = useState(true);

    const [playersData, setPlayersData] = useState(null);
    const [teamsData, setTeamsData] = useState(null);

    useEffect(() => {
        fetchPlayersData().then((jsonData) => {
            setPlayersData(jsonData);
        }).catch((error) => {
            console.error('Error fetching data:', error);
        });

        fetchTeamsData().then((jsonData) => {
            setTeamsData(jsonData);
        }).catch((error) => {
            console.error('Error fetching data:', error);
        });
    }, []);

    useEffect(() => {
        if (playersData && teamsData) {
            setLoading(false);
        }
    }, [playersData, teamsData]);

    return (
        <>
            <CustomContainer title="Inscritos">
                {!loading && (
                    <GridContainer>
                        {!classificationLoading && (
                            <MainContentContainer>
                                <CustomCard>
                                    <h3 style={{margin: "0"}}>Jogadores</h3>
                                    {playersData.length > 0 ?
                                        <CustomTable
                                            data={playersData}
                                            pagination={10}
                                            sort={true}
                                            search={true}
                                            headers={[
                                                {label: 'Nome', key: 'nome'},
                                                {label: 'Equipa', key: 'equipa'},
                                                {label: 'Golos', key: 'golos', align: 'center'},
                                                {label: 'Amarelos', key: 'cartoes_amarelos', align: 'center'},
                                                {label: 'Vermelhos', key: 'cartoes_vermelhos', align: 'center'},
                                            ]}
                                            width="100%"
                                        />
                                        : <h4>Não existem dados de jogadores</h4>}
                                </CustomCard>
                                <CustomCard style={{marginTop: "30px"}}>
                                    <h3 style={{margin: "0"}}>Equipas</h3>
                                    {teamsData.length > 0 ?
                                        <CustomTable
                                            data={teamsData}
                                            pagination={10}
                                            sort={true}
                                            search={true}
                                            headers={[
                                                {label: 'Nome', key: 'nome'},
                                                {label: 'Pontos', key: 'pontos', align: 'center'},
                                                {label: 'Golos', key: 'golos_marcados', align: 'center'},
                                                {label: 'Sofridos', key: 'golos_sofridos', align: 'center'},
                                                {label: 'Amarelos', key: 'cartoes_amarelos', align: 'center'},
                                                {label: 'Vermelhos', key: 'cartoes_vermelhos', align: 'center'},
                                            ]}
                                            width="100%"
                                        />
                                        : <h4>Não existem dados de equipas</h4>}
                                </CustomCard>
                            </MainContentContainer>
                        )}
                        <Classification showEliminationGames={true} showActiveGames={true}
                                        style={{flex: "1 1 30%", marginBottom: "20px"}}
                                        onLoadingChange={setClassificationLoading}/>
                    </GridContainer>
                )}
            </CustomContainer>
            {!loading && !classificationLoading && <Footer/>}
        </>
    );
}