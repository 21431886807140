import styled from "styled-components";
import {ReactComponent as Logo} from '../assets/LogoAM2.svg';
import Divider from '@mui/material/Divider';

const FooterContainer = styled.footer`
    background-color: green;
    color: white;
    width: 100%;

    p {
        margin: 0;
    }
`;

const FooterRights = styled.div`
    background-color: #1d2a43;
    padding: 25px 0;
    text-align: center;
    color: #bababa;

    p {
        line-height: 24px;
    }
`;

const FooterContacts = styled.div`
    background-color: #27344e;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;
`;

const FooterInfo = styled.div`
    padding: 0 30px;

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }
`;

const FooterInfoHeader = styled.h5`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 28px;
    margin-top: 0;
`;

const FooterVolunteersLink = styled.a`
    color: #ffffff;
    text-decoration: none;
    transition: color 0.5s ease-in-out;

    &:hover {
        color: #daa51f !important;
    }
`;

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <FooterContainer>
            <FooterContacts>
                <FooterInfo style={{width: "200px", padding: "0"}}>
                    <a href="/">

                        <Logo style={{width: "100%", height: "100%"}}/>
                    </a>
                </FooterInfo>
                <div style={{marginTop: "20px", marginBottom: "20px", display: "flex", gap: "15px"}}>
                    <FooterInfo>
                        <FooterInfoHeader>Contactos</FooterInfoHeader>
                        <Divider sx={{backgroundColor: "#ffffff"}} flexItem/>
                        <p style={{marginTop: "10px"}}>Atendimento: +351 291 705 060.</p>
                        <p>Deixe mensagem de voz após o sinal.</p>
                        <p>Chamada para a rede fixa nacional.</p>
                        <p>Integra,{" "}
                            <FooterVolunteersLink href="https://souvoluntario.pt/" style={{
                                color: "#ffffff",
                                textDecoration: "none"
                            }} target="_blank">
                                aqui</FooterVolunteersLink>,
                            a nossa equipa.
                        </p>
                    </FooterInfo>
                    <FooterInfo>
                        <FooterInfoHeader>Localização</FooterInfoHeader>
                        <Divider sx={{backgroundColor: "#ffffff"}} flexItem/>
                        <p style={{marginTop: "10px"}}>Universidade da Madeira</p>
                        <p>Campus Universitário da Penteada</p>
                        <p>Piso -1</p>
                        <p>9020-105 Funchal</p>
                        <p>Portugal</p>
                    </FooterInfo>
                </div>
            </FooterContacts>
            <FooterRights>
                <p style={{fontSize: "16px", padding: "0 30px"}}>© Todos direitos reservados à ACADÉMICA DA MADEIRA
                    (1991
                    – {currentYear}) </p>
                <p style={{fontSize: "12px", padding: "0 70px"}}>Este portal obedece ao disposto nas nossas {" "}
                    <a href="https://amadeira.pt/ppdp.html" target="_blank"
                       style={{color: "#daa51f", textDecoration: "none"}}>
                        Políticas de Privacidade</a>.
                </p>
            </FooterRights>
        </FooterContainer>
    );
}