import styled from "styled-components";

const CardContainer = styled.div`
    background: linear-gradient(to bottom, #262d45, #36516e);
    color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: ${props => props.width || '100%'};
    height: 150px;
    margin: 20px 0 5px 0;

    p {
        text-align: center;
    }
`;

const CardContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 100%;
`;

const VS = styled.div`
    background-color: #7c859a;
    border-radius: 30px;
    padding: 0 7px 2px 6px;
`;

const TeamData = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    min-height: 100%;
`;

const GamePhase = styled.p`
    margin: 0 0 15px 0;
    color: #daa51f;
    font-size: 1.4rem;
    font-weight: 550;
`;

const TeamName = styled.p`
    margin: 0 0 5px 0;
    overflow: hidden;
    font-size: 1.1rem;
    text-overflow: ellipsis;
    max-width: 100%
`;

const TeamGoals = styled.p`
    margin: 0 0 0 0;
    background-color: #daa51f;
    color: black;
    width: fit-content;
    padding: 3px 6px 4px 6px;
    border-radius: 5px;
`;

export default function CustomActiveGameCard({data, width}) {
    return (
        <CardContainer width={width}>
            <div style={{height: "50%", padding: "13px 20px 13px 20px"}}>
                {data.fase === 'Grupo' ? (
                    <GamePhase>{data.grupo}</GamePhase>
                ) : data.fase === 'Meia Final' ? (
                    <GamePhase>Meia Final </GamePhase>
                ) : data.fase === '5Classificado' ? (
                    <GamePhase>5.º e 6.º Classifcado</GamePhase>
                ): data.fase === '3Classificado' ? (
                    <GamePhase>3.º e 4.º Classifcado</GamePhase>
                ):(
                    <GamePhase>Final</GamePhase>
                )}
                <CardContentContainer>
                    <TeamData>
                        <TeamName>
                            {data['equipa-1'].nome}
                        </TeamName>
                        <TeamGoals>
                            {data['equipa-1'].golos}
                        </TeamGoals>
                    </TeamData>
                    <VS>
                        vs
                    </VS>
                    <TeamData>
                        <TeamName>
                            {data['equipa-2'].nome}
                        </TeamName>
                        <TeamGoals>
                            {data['equipa-2'].golos}
                        </TeamGoals>
                    </TeamData>
                </CardContentContainer>
            </div>
        </CardContainer>
    );
}

