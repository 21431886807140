import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {About, Games, Home, PlayersAndTeams, Stats} from './pages';
import Layout from "./layout/Layout";

export default function App() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/sobre" exact element={<About/>}/>
                    <Route path="/inscritos" exact element={<PlayersAndTeams/>}/>
                    <Route path="/jogos" exact element={<Games/>}/>
                    <Route path="/" exact element={<Home/>}/>
                    <Route path="/estatisticas" exact element={<Stats/>}/>
                </Routes>
            </Layout>
        </Router>
    );
}