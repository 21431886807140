export function changeNameFormat(teamName) {
    return teamName.toLowerCase().split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
}

export function calculateClassification(groupClassification) {

    groupClassification.sort((a, b) => {
        if (a.pontos !== b.pontos) {
            return b.pontos - a.pontos;
        } else {
            const diffScoredSufferedA = a.golos_marcados - a.golos_sofridos;
            const diffScoredSufferedB = b.golos_marcados - b.golos_sofridos;

            if (diffScoredSufferedA !== diffScoredSufferedB) {
                return diffScoredSufferedB - diffScoredSufferedA;
            } else {
                if (a.golos_marcados !== b.golos_marcados) {
                    return b.golos_marcados - a.golos_marcados;
                } else {
                    if (a.golos_sofridos !== b.golos_sofridos) {
                        return a.golos_sofridos - b.golos_sofridos;
                    } else {
                        return a.cartoes_amarelos - b.cartoes_amarelos;
                    }
                }
            }
        }
    });
    groupClassification.forEach((item, index) => {
        item.posicao = (index + 1) + ".";
    });

    return groupClassification;
}