import React from "react";
import CustomContainer from "../components/reusableComponents/customContainer";

export default function About() {
    return (
        <CustomContainer>
            <h1>About</h1>
            <p>Welcome to the about page!</p>
        </CustomContainer>
    );
}