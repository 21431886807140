import React, {useState} from "react";
import styled from "styled-components";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ClearIcon from '@mui/icons-material/Clear';

const TableWrapper = styled.div`
    border: 1px solid #9d9d9d;
    border-radius: 5px;
    overflow-x: auto;
    width: ${props => props.width || '100%'};
    max-width: 100vw;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const TableHead = styled.thead`
  background: linear-gradient(to bottom, #262d45, #262d45);
  text-transform: uppercase;
`;

const TableBody = styled.tbody`
`;

const TableHeader = styled.th`
    padding: 12px 8px 12px 8px;
    text-align: ${props => props.align || 'left'} !important;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
`;

const TableHeaderSorter = styled.p`
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;
    width: fit-content;
`;

const TableCell = styled.td`
    padding: 8px;
    text-align: ${props => props.align || 'left'};
    font-size: 0.9rem;
`;

const TableRow = styled.tr`
    &:nth-child(odd) {
        background-color: #ffffff;
    }

    &:nth-child(even) {
        background-color: #f2f2f2;
    }

    &:hover {
        background-color: #d3d3d3;
        transition: 0.5s;
    }
`;

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 10px;
    align-items: center;
`;

const SearchWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: end;
    margin: 10px 0;
    align-items: center;
`;

const PaginationButton = styled.button`
    margin: 0 5px;
    font-size: 1rem;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
`;

const SearchInput = styled.input`
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #9d9d9d;
    font-size: 0.9rem;
    padding-right: 25px;
`;

const ClearButton = styled.button`
    position: absolute;
    padding: 0;
    right: 1px;
    top: 55%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    display: ${({hasContent}) => (hasContent ? 'block' : 'none')}; /* Show button only when input has content */
`;

export default function CustomTable({headers, data, width, classification, pagination, sort, search}) {
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(pagination || data.length);
    const [sortOrder, setSortOrder] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [hasContent, setHasContent] = useState(false);

    const defaultData = data;

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const sortedData = [...data].sort((a, b) => {
        const key = Object.keys(sortOrder)[0];
        if (!key) return 0;
        const order = sortOrder[key];
        const valueA = parseFloat(a[key]);
        const valueB = parseFloat(b[key]);
        let compareResult;
        if (isNaN(valueA) || isNaN(valueB)) {
            compareResult = a[key].localeCompare(b[key]);
        } else {
            compareResult = valueA - valueB;
        }
        if (order === 'ascending') {
            return compareResult;
        } else if (order === 'descending') {
            return -compareResult;
        } else {
            return defaultData;
        }
    });

    const filteredData = sortedData.filter(item => {
        const values = Object.values(item).map(val => val.toString().toLowerCase());
        return values.some(val => val.includes(searchQuery.toLowerCase()));
    });

    const displayedData = filteredData.slice(startIndex, endIndex);

    const totalPages = Math.ceil(data.length / rowsPerPage);

    const toggleSortOrder = (key) => {
        const currentOrder = sortOrder[key];
        let newOrder;
        switch (currentOrder) {
            case 'ascending':
                newOrder = 'descending';
                break;
            case 'descending':
                newOrder = null;
                break;
            default:
                newOrder = 'ascending';
        }
        setSortOrder({[key]: newOrder});
    };

    const nextPage = () => {
        setCurrentPage(currentPage => Math.min(currentPage + 1, totalPages));
    };

    const prevPage = () => {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    };

    const handleRowsPerPageChange = (event) => {
        const newPageSize = parseInt(event.target.value);
        setRowsPerPage(newPageSize);
        setCurrentPage(1);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to first page when search query changes
    };

    const handleChange = (event) => {
        setHasContent(event.target.value.length > 0); // Set hasContent based on input value length
        handleSearchInputChange(event); // Call parent handler
    };

    const clearInput = () => {
        handleSearchInputChange({target: {value: ''}}); // Clear input
        setHasContent(false); // Reset hasContent
    };

    return (
        <>
            {search && (
                <SearchWrapper>
                    <SearchInput onChange={handleChange} value={searchQuery} hasContent={hasContent}
                                 placeholder="Procurar..."/>
                    <ClearButton onClick={clearInput} hasContent={hasContent}>
                        <ClearIcon/>
                    </ClearButton>
                </SearchWrapper>)}
            <TableWrapper width={width}>
                <Table>
                    <TableHead>
                        <tr>
                            {sort ? (
                                headers.map(header => (
                                    <TableHeader key={header.key} align={header.align}>
                                        {header.align === 'center' ?
                                            (<div style={{display: "flex", justifyContent: "center"}}>
                                                <TableHeaderSorter
                                                    onClick={() => toggleSortOrder(header.key)}>
                                                    {header.label}
                                                    {sortOrder[header.key] === 'ascending' &&
                                                        <KeyboardArrowUpIcon/>}
                                                    {sortOrder[header.key] === 'descending' &&
                                                        <KeyboardArrowDownIcon/>}
                                                </TableHeaderSorter>
                                            </div>)
                                            : (
                                                <TableHeaderSorter
                                                    onClick={() => toggleSortOrder(header.key)}>
                                                    {header.label}
                                                    {sortOrder[header.key] === 'ascending' &&
                                                        <KeyboardArrowUpIcon/>}
                                                    {sortOrder[header.key] === 'descending' &&
                                                        <KeyboardArrowDownIcon/>}
                                                </TableHeaderSorter>)}

                                    </TableHeader>
                                ))) : (
                                headers.map(header => (
                                    <TableHeader key={header.key} align={header.align}>
                                        {header.label}
                                    </TableHeader>
                                ))

                            )}
                        </tr>
                    </TableHead>
                    {displayedData.length > 0 ? (
                        <TableBody>
                            {displayedData.map((item, index) => (
                                <TableRow key={index}>
                                    {headers.map(header => (
                                        <React.Fragment key={header.key}>
                                            {classification && header.key === 'posicao' ? (
                                                item[header.key] === '1.' || item[header.key] === '2.' ? (
                                                    <TableCell key={header.key} align={header.align}
                                                               style={{width: "20px"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            borderRadius: "5px",
                                                            padding: "3px 2px",
                                                            backgroundColor: "#daa51f"
                                                        }}>{item[header.key]}</p>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell key={header.key} align={header.align}
                                                               style={{width: "20px"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            padding: "3px 2px",
                                                        }}>{item[header.key]}</p>
                                                    </TableCell>
                                                )
                                            ) : (
                                                <TableCell key={header.key}
                                                           align={header.align}>{item[header.key]}</TableCell>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={headers.length} style={{textAlign: "center", padding: "10px"}}>
                                    <p style={{margin: "0", fontSize: "1.1rem"}}>Não há resultados</p>
                                </TableCell>
                            </TableRow>
                        </TableBody>)}
                </Table>
            </TableWrapper>
            {pagination && (
                <PaginationWrapper>
                    <select style={{marginRight: "10px", borderRadius: "5px", fontSize: "0.9rem"}} value={rowsPerPage}
                            onChange={handleRowsPerPageChange}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                    </select>
                    <PaginationButton onClick={prevPage}
                                      disabled={currentPage === 1}><KeyboardArrowLeftIcon/></PaginationButton>
                    {Array.from({length: totalPages}, (_, i) => (
                        <PaginationButton key={i + 1} disabled={currentPage === i + 1} style={{marginBottom: "1px"}}
                                          onClick={() => setCurrentPage(i + 1)}>{i + 1}</PaginationButton>
                    ))}
                    <PaginationButton onClick={nextPage} disabled={currentPage === totalPages}><KeyboardArrowRightIcon/></PaginationButton>
                </PaginationWrapper>)}
        </>
    );
}